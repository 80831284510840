import { FC } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { useSite } from '../../Context/SiteContext';
import { usePayment } from '../../Context/PaymentContext';
import Logo from '../Logo';

export interface IPageHeader {
  theme?: DefaultTheme;
}

const Header = styled.header(({ theme }) => {
  const { breakpoint } = theme;

  return `
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding-top: 30px;

    @media screen and (min-width: ${breakpoint.phone}) {
      padding-top: 80px;
    }
  `;
});

const HeaderLogo = styled(Logo)(({ theme }) => {
  const { breakpoint } = theme;

  return `
    height: 22px;
    width: 140px;

    @media screen and (min-width: ${breakpoint.phone}) {
      height: 40px;
      width: 263px;
    }
  `;
});

const PageHeader: FC<IPageHeader> = () => {
  const { logoLink } = useSite();
  const { state }    = usePayment();
  return (
    <Header>
      <a href={state.customer?.cancelUrl?`${state.customer.clientUrl}/cart`:"https://www.Arhaus.com"}>
        <HeaderLogo title={logoLink.linkText} />
      </a>
    </Header>
  );
};

export default PageHeader;
