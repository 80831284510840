import { FC } from 'react';
import styled from 'styled-components';
//import Breadcrumbs from '../../Components/Breadcrumbs';
// No test for placeholder component
// Change page name and component in next task

const Heading = styled.h1`
  text-align: center;
  margin-top: 2rem;
`;

const Invalid: FC = () => {
  return (
    <div>
      {/* <Breadcrumbs /> */}
      
      <Heading>We&apos;re sorry</Heading>
      <p>
        <strong>We encountered an issue while processing your order and it could not be completed.</strong>
      </p>
      <p>
        Please call our website assistance line at <strong>1-866-427-4287</strong> for further assistance.
      </p>
    </div>
  );
};

export default Invalid;
