import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  type: 'button' | 'reset' | 'submit';
  variant?: 'secondary';
}

const Button = styled.button<IButton>(({ theme, variant }) => {
  const { breakpoint, color, size } = theme;

  let variantStyles: string;

  switch (variant) {
    case 'secondary':
      variantStyles = `
        color: ${color.primary[500]};
        background: ${color.grayscale[100]};
        border-color: ${color.primary[500]};

        &:hover {
          color: ${color.primary[500]};
          background: ${color.grayscale[200]};
          border-color: ${color.primary[500]};
        }

        &:focus {
          color: ${color.primary[500]};
          background: ${color.primary[200]};
          border-color: ${color.primary[500]};
        }

        &:disabled {
          color: ${color.grayscale[500]};
          background: ${color.grayscale[200]};
          border-color: ${color.grayscale[200]};
        }
      `;
      break;
    default:
      variantStyles = '';
  }

  return `
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    background: #1e372f;
    border: 1px solid ${color.primary[500]};
    border-radius: 0;
    box-sizing: border-box;
    color: ${color.primary[100]};
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-family: "Neue Haas Unica",sans-serif
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.1;
    min-height: ${size.formElementHeight};
    padding: 10px 20px;
    text-align: center;
    text-transform: capitalize;
    vertical-align: top;
    radius:5px;

    &:hover {
      color: ${color.grayscale[100]};
      background: #4b5f59;
      border-color: ${color.primary[1000]};
    }

    &:focus {
      color: ${color.grayscale[100]};
      background: ${color.primary[1000]};
      border-color: ${color.primary[1000]};
    }

    &:disabled {
      color: ${color.grayscale[500]};
      background: ${color.grayscale[200]};
      border-color: ${color.grayscale[200]};
      pointer-events: none;
      cursor: default;
      outline: none !important;
    }

    @media screen and (min-width: ${breakpoint.phone}) {
      min-width: 182px;
    }

    ${variantStyles}
  `;
});

export default Button;
