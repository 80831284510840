import { FC, ReactNode, createContext, useContext, useReducer } from 'react';
import { Payment } from '../Types';

type UpdateCard = {
  type: 'updateCard';
  data: Payment.Card;
};

type UpdateCustomer = {
  type: 'updateCustomer';
  data: Payment.Customer;
};

type UpdatePlans = {
  type: 'updatePlans';
  data: Payment.Plan[];
};

type FormChange = {
  type: 'formChange';
  field: string;
  value: string;
};

type FormError = {
  type: 'formError';
  field: string;
  value: string;
};

type ClearErrors = {
  type: 'clearErrors';
};

type FormRetry = {
  type: 'formRetry';
  data: boolean;
};

type Action = UpdateCard | UpdateCustomer | UpdatePlans | FormChange | FormError | ClearErrors | FormRetry;

type Dispatch = (action: Action) => void;

type PaymentProviderProps = { children: ReactNode; initialState?: Payment.State };

type PaymentObject = { state: Payment.State; dispatch: Dispatch };

const PaymentContext = createContext<PaymentObject | undefined>(undefined);

export const paymentReducer = (state: Payment.State, action: Action): Payment.State => {
  switch (action.type) {
    case 'updateCard': {
      return { ...state, card: action.data as Payment.Card };
    }

    case 'updateCustomer': {
      return {
        ...state,
        customer: action.data as Payment.Customer,
      };
    }

    case 'updatePlans': {
      return { ...state, paymentPlans: action.data as Payment.Plan[] };
    }

    case 'formChange': {
      return { ...state, form: { ...state.form, [action.field]: action.value } };
    }

    case 'formError': {
      return { ...state, formError: { ...state.formError, [action.field]: action.value } };
    }

    case 'clearErrors': {
      return {
        ...state,
        formError: {
          cardNumber: undefined,
          paymentPlan: undefined,
          expiration: undefined,
          cvv: undefined,
        },
      };
    }

    case 'formRetry': {
      return { ...state, formRetry: action.data };
    }

    default: {
      throw new Error(`Unhandled action type.`);
    }
  }
};

const PaymentProvider: FC<PaymentProviderProps> = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(
    paymentReducer,
    initialState || {
      card: undefined,
      customer: undefined,
      pageState: 'loading',
      paymentPlans: undefined,
      form: {
        cardNumber: undefined,
        paymentPlan: undefined,
        expMonth: undefined,
        expYear: undefined,
        cvv: undefined,
      },
      formError: {
        cardNumber: undefined,
        paymentPlan: undefined,
        expiration: undefined,
        cvv: undefined,
      },
      formRetry: false,
    },
  );
  const value = { state, dispatch };

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;
};

const usePayment = (): PaymentObject => {
  const context = useContext(PaymentContext);

  if (context === undefined) {
    throw new Error('usePayment must be used within a PaymentProvider');
  }

  return context;
};

export { PaymentProvider, usePayment };
