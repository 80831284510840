import { FC } from 'react';
import styled from 'styled-components';
import BlockHeader from '../BlockHeader';

export interface IOrderSummary {
  currency: Intl.NumberFormatOptions['currency'];
  total: number;
}

const Summary = styled.div(({ theme }) => {
  const { breakpoint, color } = theme;

  return `
    padding: 20px 19px;
    display: flex;
    flex-flow: row nowap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid ${color.grayscale[300]};
    border-radius:5px;
    font-size: 0.875rem;

    @media screen and (min-width: ${breakpoint.phone}) {
      font-size: 1rem;
    }
`;
});

const Total = styled.span`
  font-weight: 500;
  font-size: 14px;
  font-family: NeueHaasUnica;
  flex-grow: 1;
  line-height: 1;
  text-transform: capitalize;
`;

const Currency = styled.span(({ theme }) => {
  const { color } = theme;

  return `
    font-size: 0.6875rem;
    color: ${color.grayscale[550]};
    line-height: 1;
  `;
});

const Price = styled.span`
  font-weight: 500;
  font-size: 14px;
  font-family: NeueHaasUnica;
  margin-left: 5px;
  line-height: 1;
`;

const OrderSummary: FC<IOrderSummary> = ({ currency, total }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  });

  return (
    <section>
      <BlockHeader>Order Summary</BlockHeader>

      <Summary>
        <Total>Total</Total>
        <Currency>{currency}</Currency>
        <Price>{formatter.format(total)}</Price>
      </Summary>
    </section>
  );
};

export default OrderSummary;
