import { FC } from 'react';
import styled from 'styled-components';

type Order = {
  contact: string;
  shipTo: string;
  billTo: string;
};

export interface IOrderInfo {
  order: Order;
}

const InfoContainer = styled.div(({ theme }) => {
  const { breakpoint, color } = theme;

  return `
    border: 1px solid ${color.grayscale[300]};
    border-radius:5px;
    border-collapse: collapse;
    margin: 0;
    padding-left:16px;
    padding-right:16px;
    width: 100%;
    font-size: 0.75rem;

    @media screen and (min-width: ${breakpoint.phone}) {
      font-size: 0.875rem;
    }
  `;
});

const InfoTable = styled.table(({ theme }) => {
  const { breakpoint, color } = theme;

  return `
  border-collapse: collapse;
  margin: 0;
  width: 100%;
  font-size: 0.75rem;

  @media screen and (min-width: ${breakpoint.phone}) {
    font-size: 0.875rem;
  }
`;
});

const InfoRow = styled.tr(({ theme }) => {
  const { color } = theme;

  return `
    border-bottom: 1px solid ${color.grayscale[300]};
    line-height: 1.3em;
  `;
});

const InfoHeading = styled.th(({ theme }) => {
  const { breakpoint, type } = theme;

  return `
    font-weight: ${type.weight.regular};
    font-size: ${type.baseSize.small};
    font-family:NeueHaasUnica;
    color: #737373;
    text-align: left;
    display: block;

    @media screen and (min-width: ${breakpoint.phone}) {
      display: table-cell;
      padding: 12px 16px;
    }
  `;
});

const InfoData = styled.td(({ theme }) => {
  const { breakpoint, type } = theme;

  return `
  font-weight: ${type.weight.regular}
  font-size: ${type.baseSize.small};
  font-family: NeueHaasUnica;
  
    padding: 4px 15px 19px;
    display: block;
    
    @media screen and (min-width: ${breakpoint.phone}) {
        display: table-cell;
        padding: 12px 16px;
      }
  `;
});

const OrderInfo: FC<IOrderInfo> = ({ order }) => {
  const { billTo, contact, shipTo } = order;

  return (
    <InfoContainer>
      <InfoTable cellPadding="0" cellSpacing="0">
      <tbody>
        <InfoRow>
          <InfoHeading>Contact</InfoHeading>
          <InfoData>{contact}</InfoData>
        </InfoRow>

        <InfoRow>
          <InfoHeading scope="row">Ship to</InfoHeading>
          <InfoData>{shipTo}</InfoData>
        </InfoRow>

        <InfoRow style={{ borderBottom: 0 }}>
          <InfoHeading scope="row">Bill to</InfoHeading>
          <InfoData>{billTo}</InfoData>
        </InfoRow>
      </tbody>
    </InfoTable>
    </InfoContainer>
  );
};

export default OrderInfo;
