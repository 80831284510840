import { createContext, useContext } from 'react';
import { Site } from '../Types';

export interface ISiteContext {
  baseSiteUrl: string;
  cliendId: string;
  baseSiteName: string;
  apiUrl: string;
  apiVersion: string;
  environment: string;
  breadCrumbLinks: Site.Breadcrumb[];
  footerLinks: Site.Link[];
  logoLink: Site.Link;
}

const SiteContext = createContext<ISiteContext>(undefined);

export const useSite = (): ISiteContext => {
  const context = useContext(SiteContext);
  if (context === undefined) {
    throw new Error('useSite must be used within a SiteContext.Provider');
  }
  return context;
};

export default SiteContext;
