import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { LoaderProvider } from './Context/LoaderContext';
import { PaymentProvider } from './Context/PaymentContext';
import SiteContext, { ISiteContext } from './Context/SiteContext';
import * as Links from './Library/links';
import { CookiesProvider } from 'react-cookie';
import TagManager from 'react-gtm-module'

const defaultValue: ISiteContext = {
  baseSiteUrl: process.env.REACT_APP_SITE_URL,
  cliendId: process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID,
  baseSiteName: process.env.REACT_APP_BASE_SITE_NAME,
  apiUrl: process.env.REACT_APP_API_URL,
  apiVersion: process.env.REACT_APP_API_VERSION,
  environment: process.env.NODE_ENV,
  breadCrumbLinks: Links.breadcrumbLinks,
  footerLinks: Links.footerLinks,
  logoLink: Links.logoLink,
};
// register GTM
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <StrictMode>
    <SiteContext.Provider value={defaultValue}>
      <LoaderProvider>
        <PaymentProvider>
        <CookiesProvider>
          <Router>
            <App />
          </Router>
          </CookiesProvider>
        </PaymentProvider>
      </LoaderProvider>
    </SiteContext.Provider>
  </StrictMode>,
  document.getElementById('root'),
);
