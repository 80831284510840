import { FC } from 'react';
import styled from 'styled-components';
import { useSite } from '../../Context/SiteContext';
import { usePayment } from '../../Context/PaymentContext';
import Separator from '../Separator';
import { useFeatureFlag } from '../../Utils/appsettingsHelper';

const LinkContainer = styled.span`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const Link = styled.a(({ theme }) => {
  const { color, type } = theme;

  return `
    color: ${color.grayscale[950]};
    text-transform: capitalize;
    font-size: 0.75em;
    line-height:1.3em;
    text-decoration: none;
    font-family:NeueHaasUnica;
    font-weight: ${type.weight.regular};
    vertical-align: middle;
  `;
});

const CurrentPage = styled.span(({ theme }) => {
  const { color } = theme;

  return `
    color: ${color.grayscale[950]};
    text-transform: capitalize;
    font-size: 0.75em;
    line-height:1.3em;
    vertical-align: middle;
    font-family:NeueHaasUnica;
    font-weight: 500;
  `;
});

const BreadcrumbSeparator = styled(Separator)`
  margin: 0 10px;
  height: 10px;
  width: 5px;
`;

const Nav = styled.nav(({ theme }) => {
  const { breakpoint } = theme;

  return `
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    padding: 22px 0;

    @media screen and (min-width: ${breakpoint.phone}) {
      padding: 18px 0;
    }
  `;
});

const Breadcrumbs: FC = () => {
  const { breadCrumbLinks } = useSite();
  const { state }           = usePayment();
  const { enabled: checkOutExtensibilityEnabled } = useFeatureFlag(process.env.REACT_APP_SITEMAPV2_ENABLED);

  const linkArray = breadCrumbLinks.map((link) => {
        
    if(checkOutExtensibilityEnabled){
      if((link.linkText === 'Cart') || (link.linkText==='Billing')){
        const linktext =link.linkText==='Billing'? 'Billing/Shipping' : link.linkText;
         const href = state.customer?.cancelUrl? link.linkText==='Cart'?`${state.customer.clientUrl}/cart`:`${state.customer.cancelUrl}?step=shipping_billing_method`:"https://www.Arhaus.com";
        return  (
          <LinkContainer key={link.linkText.replace(/ /g, '')}>
            <Link href={href}>
              {linktext}
            </Link>   
            <BreadcrumbSeparator />
          </LinkContainer>
        );
      }
      
    } 
    else {
       return (
      <LinkContainer key={link.linkText.replace(/ /g, '')}>
        <Link href={state.customer?.cancelUrl? link.linkText==='Cart'?`${state.customer.clientUrl}/cart`:`${state.customer.cancelUrl}${link.query ? link.query : ''}`:"https://www.Arhaus.com"}>
          {link.linkText}
        </Link>   
        <BreadcrumbSeparator />
      </LinkContainer>
    )}
  });

  return (
    <Nav>
      {linkArray}
      <CurrentPage>Payment</CurrentPage>
    </Nav>
  );
};

export default Breadcrumbs;
