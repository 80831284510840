import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

export interface ITextInput extends InputHTMLAttributes<HTMLInputElement> {
  type: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url';
}

const TextInput = styled.input<ITextInput>(({ theme }) => {
  const { breakpoint, color, size } = theme;

  return `
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: ${size.formElementHeight};
    padding: ${size.formElementPadding};
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    border: 1px solid ${color.grayscale[300]};
    border-radius:5px;

    &:focus {
      border-color: ${color.grayscale[900]};
    }

    &:-webkit-autofill {
      filter: none;
      -webkit-box-shadow: 0 0 0 1000px ${color.grayscale[100]} inset !important;
      box-shadow: 0 0 0 1000px ${color.grayscale[100]}  inset !important;
    }

    @media screen and (min-width: ${breakpoint.phone}) {
      font-size: 0.875rem;
    }
  `;
});

TextInput.defaultProps = {
  type: 'text',
};

export default TextInput;
