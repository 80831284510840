import { FC, SVGAttributes } from 'react';
import { Archarge, Amex, Discover, Mastercard, Visa } from './Cards';

export interface ICardIcon extends SVGAttributes<SVGSVGElement> {
  cardType: 'archarge' | 'visa' | 'mastercard' | 'discover' | 'amex';
  grayscale?: boolean;
}

const CardIcon: FC<ICardIcon> = ({ cardType, grayscale, ...props }) => {
  let icon;

  switch (cardType) {
    case 'amex':
      icon = <Amex />;
      break;
    case 'discover':
      icon = <Discover />;
      break;
    case 'mastercard':
      icon = <Mastercard />;
      break;
    case 'visa':
      icon = <Visa />;
      break;
    default:
      icon = <Archarge />;
      break;
  }

  return (
    <svg viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img" width="38" height="24" style={{filter: grayscale ? 'grayscale(100%)' : 'grayscale(0%)'}} {...props}>
      {icon}
    </svg>
  );
};

export default CardIcon;
