import { FunctionComponent, createContext, useContext, useReducer,ReactNode } from 'react';

interface LoaderState {
  count: number;
}

type LoaderAction = 'open' | 'close' | 'forceClose' | 'notReal' ;

type LoaderContext = {
  loaderState: LoaderState;
  loaderDispatch: (action: LoaderAction) => void;
};

interface LoaderProviderProps {
  children: ReactNode;
}


const LoaderContext = createContext(undefined);

export const loaderReducer = (state: LoaderState, action: LoaderAction): LoaderState => {
  switch (action) {
    case 'open': {
      return { count: state.count + 1 };
    }
    case 'close': {
      return state.count > 0 ? { count: state.count - 1 } : { count: state.count };
    }
    case 'forceClose': {
      return { count: 0 };
    }
    case 'notReal':

    default: {
      throw new Error('Unhandled action type.');
    }
  }
};

const LoaderProvider: FunctionComponent<LoaderProviderProps>= ({ children }) => {
  const [state, dispatch] = useReducer(loaderReducer, { count: 0 });

  const value = { loaderState: state, loaderDispatch: dispatch };

  return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>;
};

const useLoader = (): LoaderContext => {
  const context = useContext(LoaderContext);

  if (context === undefined) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }

  return context;
};

export { LoaderProvider, useLoader };
