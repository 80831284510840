import { FC, SVGAttributes } from 'react';
import styled from 'styled-components';

const SeparatorSvg = styled.svg(({ theme }) => {
  const { color } = theme;

  return `
    path {
      fill: ${color.grayscale[950]};
      stroke: ${color.grayscale[950]};
    }
  `;
});

const Separator: FC<SVGAttributes<SVGSVGElement>> = ({ ...props }) => {
  return (
    <SeparatorSvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 7 12" {...props} data-testid="separator">
      <path
        fill="#1D1D1D"
        stroke="#1D1D1D"
        strokeWidth=".5"
        d="M.886 10.42h0a.496.496 0 000 .683.47.47 0 00.683 0L6.114 6.34a.496.496 0 000-.682L1.57.897a.47.47 0 00-.683 0 .496.496 0 000 .682l4.222 4.423-4.222 4.419z"
      />
    </SeparatorSvg>
  );
};

export default Separator;
