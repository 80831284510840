import { FC, forwardRef, RefObject, SelectHTMLAttributes } from 'react';
import styled, { DefaultTheme } from 'styled-components';

export type SelectOption = {
  label: string;
  value: string;
  disabled?: boolean;
};

export interface ISelectInput extends SelectHTMLAttributes<HTMLSelectElement> {
  options?: SelectOption[];
  ref: RefObject<HTMLSelectElement>;
  theme?: DefaultTheme;
  defaultValue?: string;
}

const Select = styled.select<SelectHTMLAttributes<HTMLSelectElement>>(({ theme }) => {
  const { breakpoint, color, size } = theme;
  return `
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    appearance: none;
    box-sizing: border-box;
    height: ${size.formElementHeight};
    padding: ${size.formElementPadding};
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.75rem;
    line-height: 1.3;
    background: ${color.grayscale[100]};
    border: 1px solid ${color.grayscale[300]};
    border-radius:5px;
    width: 100%;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      border-color: ${color.grayscale[900]};
    }

    &:invalid {
      border-color: $messages-error;
    }

    @media screen and (min-width: ${breakpoint.phone}) {
      font-size: 0.875rem;
    }
  `;
});

const Container = styled.div(({ theme }) => {
  const { color } = theme;

  return `
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 12px;
      width: 6px;
      height: 6px;
      margin-top: -4px;
      border-style: solid;
      border-width: 0 0 1px 1px;
      border-color: ${color.grayscale[700]};
      transform: rotate(-45deg);
      cursor: pointer;
      pointer-events: none;
    }
  `;
});

const SelectInput = forwardRef<HTMLSelectElement, FC & ISelectInput>((props, ref) => {
  const { defaultValue, options, theme } = props;

  const optionArray = options.map((option) => {
    return (
      <option key={`${option.label}${option.value}`} disabled={option.disabled} value={option.value}>
        {option.label}
      </option>
    );
  });

  return (
    <Container theme={theme}>
      <Select {...props} ref={ref} defaultValue={defaultValue}>
        {optionArray}
      </Select>
    </Container>
  );
});

SelectInput.displayName = 'Select input';

export default SelectInput;
