import { useEffect, useState } from 'react';
import './Footer.css';
import { useFeatureFlag } from '../../Utils/appsettingsHelper';
import styled from 'styled-components';
import SpinnerGif from '../../images/spinner.gif';

const Spinner = () => <img src={SpinnerGif} />;
const Footer = () => {
  const footerLinks = [
    {
      href: process.env.REACT_APP_REFUND_POLICY_URL,
      text: "Refund policy",
      isActive: true,
    },
    {
      href: process.env.REACT_APP_SHIPPING_POLICY_URL,
      text: "Shipping policy",
      isActive: true,
    },
    {
      href: process.env.REACT_APP_PRIVACY_POLICY_URL,
      text: "Privacy policy",
      isActive: true,
    },
    {
      href: process.env.REACT_APP_TERMS_OF_SERVICE_URL,
      text: "Terms of service",
      isActive: true,
    },
    {
      href: "#",
      text: "Do Not Sell My Information",
      id: "clarip-do-not-sell-link",
      isActive: false,
      loading: false,
    }
  ];

  const { enabled: footerPersonalEnabled } = useFeatureFlag(process.env.REACT_APP_FOOTER_PERSONAL_ENABLED); // The feature flag key from App Config
  //const [hasInitializedClarip, setHasInitializedClarip] = useState(false);
  //const [links, setLinks] = useState(policyLinks)
  const [state, setState] = useState({ links: footerLinks })



  const initializeClarip = () => {
    if (footerPersonalEnabled) {
      // const script = document.createElement('script');
      // //script.async = true;
      // script.src = "https://cdn.clarip.com/arhaus/donotsell/assets/js/donotsell-block.min.js";
      // document.body.appendChild(script);

      // const link = document.createElement('link');
      // link.rel = 'stylesheet';
      // link.type = 'text/css';
      // link.href = 'https://cdn.clarip.com/arhaus/donotsell/assets/css/donotsell-extended.min.css';
      // document.head.appendChild(link);

      // (window as any).globalDnsDeployment = true;
      // (window as any).claripHost = "arhaus.clarip.com";
      // (window as any).claripCdnHost = "cdn.clarip.com";
      // (window as any).clientName = "arhaus";

      const { links } = state;
      links.find(p => p.id === 'clarip-do-not-sell-link').isActive = true;
      //setLinks({...state, links: updatedLink});
      setState({ ...state, links });

      // setTimeout(() => {
      //   const { links: updatedLink } = state;
      //   updatedLink.find(p => p.id === 'clarip-do-not-sell-link').loading = false;
      //   //setLinks({...state, links: updatedLink});
      //   setState({ ...state, links: updatedLink });
      //   //setHasInitializedClarip(true)

      // }, 2000);
    }
  }

  useEffect(() => {
    initializeClarip();
  }, [footerPersonalEnabled])

  return (
    <Footer.Wrapper>
      <nav className="policy-list">
        <Footer.NavList>
          {state.links.filter(policy => policy.isActive).map(policy => (
            <li key={policy.text} className="policy-list__item">
              <a
                href="javascript:void(0);"
                style={{ cursor: 'pointer' }}
                id={policy.id}
                //className={policy?.disabled ? 'policy-disabled' : ''}
                onClick={(e) => {
                  if (policy.id !== 'clarip-do-not-sell-link') {
                    e.preventDefault();                  
                    const x = screen.width / 2 - 1000 / 2;
                    const y = screen.height / 2 - 800 / 2;
                    window.open(policy.href, 'sharegplus', 'height=800,width=1000,left=' + x + ',top=' + y + 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no');
                  }
                }}

                onContextMenu={(e) => {
                  if (policy.id === 'clarip-do-not-sell-link') {
                    e.preventDefault();
                  }
                }}
              >
                {policy.text} {policy.loading && <Spinner />}
              </a>
            </li>
          ))}
        </Footer.NavList>
      </nav>
    </Footer.Wrapper>
  );
};

export default Footer;

Footer.Wrapper = styled.footer(({ theme }) => {
  const { breakpoint, color } = theme;

  return `
    border-top : 1px solid ${color.grayscale[300]};
    padding    : 15px 0 16px;
    max-width  : 727px;
    min-width  : 320px;
    margin-top : 37px;

    @media screen and (min-width: ${breakpoint.phone}) {
      padding: 15px 0 52px;
    }
  `;
});

Footer.NavList = styled.ul(({ theme }) => {
  const { breakpoint, color } = theme;

  return `
    list-style      : none;
    margin          : 0;
    padding         : 0;
    display         : flex;
    flex-flow       : row nowrap;
    justify-content : space-between;
    align-items     : center;

    @media screen and (min-width: ${breakpoint.phone}) {
      justify-content: center;
    }

    li {
      margin      : 0;
      padding     : 0;
      text-align  : center;
      line-height : 1;

      @media screen and (min-width: ${breakpoint.phone}) {
        padding: 0 11px;
      }
    }

    a {
      color           : ${color.grayscale[950]};
      font-size       : 0.625rem;
      text-decoration : none;

      @media screen and (min-width: ${breakpoint.phone}) {
        font-size: 0.6875rem;
      }
    }
  `;
});