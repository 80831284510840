import { LabelHTMLAttributes } from 'react';
import styled from 'styled-components';

const Label = styled.label<LabelHTMLAttributes<HTMLLabelElement>>(({ theme }) => {
  const { breakpoint, color } = theme;

  return `
    color: ${color.grayscale[900]};
    font-size: 0.75rem;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 9px;

    @media screen and (min-width: ${breakpoint.phone}) {
      font-size: 0.875rem;
      margin: 0 0 5px;
    }
  `;
});

export default Label;
