import styled from 'styled-components';

const FormMessage = styled.div(({ theme }) => {
  const { color } = theme;

  return `
    font-size: 0.75rem;
    color: ${color.utility.error};
    margin-bottom: 15px;
    text-align: center;
  `;
});

export default FormMessage;
