import axios from 'axios';
import { Retrier } from '@jsier/retrier';
import { Payment } from '../Types';
import { ValidationResults } from '../Types/Payment';
import * as ValidationMessage from '../Library/validationMessages';
import Cookies from 'universal-cookie';
import TagManager from 'react-gtm-module'

axios.defaults.headers['Content-Type'] = 'application/json';

export interface IPaymentService {
  getCardInfo(cardNumber: string): Payment.Card | false;
  getPaymentPlans(total: number): Promise<{ plans: Payment.Plan[]; error: Error }>;
  luhnCheck(cardNumber: string): boolean;
  sanitizeCard(cardNumber: string): string;
  validateCardNumber(cardNumber: string, expectedLength: number[], luhnCheck: boolean): boolean;
  validatePaymentPlan(paymentPlan: string): boolean;
  validateSecurityCode(paymentPlan: string, expectedLength: number[]): boolean;
  validateExpiration(month: string, year: string): boolean;
  preparePayment(form: Payment.Form): ValidationResults;
  processPayment(request: Payment.Request, gtmEnabled: boolean): Promise<string>;
  getAccessBlock(paymentInfo: ValidationResults['sanitized']): Promise<Payment.Shift4TokenRequest>;
  getCardToken(request: { payload: Payment.Shift4Payload; url: string }): Promise<Payment.Shift4Token>;
  buildRequest(payload: Payment.ValidationResults['sanitized'], arhausId): Promise<Payment.Request>;
  checkThreshold(arhausId: string): Promise<boolean>;
}

class PaymentService implements IPaymentService {
  getCardInfo = (cardNumber: string): Payment.Card | false => {
    let cardType: Payment.Card | false = false;

    const cardTypes: Payment.Card[] = [
      {
        type: 'visa',
        patterns: [4],
        typeCode: 'VS',
        mask: '#### #### #### ####',
        cardLength: [13, 16],
        cvcLength: [3],
        luhn: true,
      },
      {
        type: 'mastercard',
        patterns: [51, 52, 53, 54, 55, 22, 23, 24, 25, 26, 27],
        typeCode: 'MC',
        mask: '#### #### #### ####',
        cardLength: [16],
        cvcLength: [3],
        luhn: true,
      },
      {
        type: 'amex',
        patterns: [34, 37],
        typeCode: 'AX',
        mask: '#### ###### #####',
        cardLength: [15],
        cvcLength: [3, 4],
        luhn: true,
      },
      {
        type: 'discover',
        patterns: [60, 64, 65, 622],
        typeCode: 'NS',
        mask: '#### #### #### ####',
        cardLength: [16],
        cvcLength: [3],
        luhn: true,
      },
      {
        type: 'archarge',
        patterns: [57,58],
        typeCode: 'archarge',
        mask: '#### #### #### ####',
        cardLength: [16],
        cvcLength: [0],
        luhn: false,
      },
    ];

    for (let i = 0; i < cardTypes.length; i += 1) {
      const filtered = cardTypes[i].patterns.filter((p) => cardNumber.substr(0, p.toString().length) === p.toString());

      if (filtered.length) {
        cardType = cardTypes[i] as Payment.Card;
        break;
      }
    }

    return cardType;
  };

  luhnCheck = (cardNumber: string): boolean => {
    const length = cardNumber.length;
    const parity = length % 2;

    let sum = 0;

    for (let i = length - 1; i >= 0; i -= 1) {
      let d = parseInt(cardNumber.charAt(i), 10);

      if (i % 2 === parity) {
        d *= 2;
      }

      if (d > 9) {
        d -= 9;
      }

      sum += d;
    }

    return sum % 10 === 0;
  };

  sanitizeCard = (cardNumber: string): string => {
    return cardNumber.replace(/[^\d]+/g, '');
  };

  getPaymentPlans = async (total: number): Promise<{ plans: Payment.Plan[]; error: Error }> => {
    const url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/payment/plans?totalAmount=${total}`;
    const response = {
      plans: undefined,
      error: undefined,
    };

    if (process.env.REACT_APP_ENVIRONMENT !== 'Production')
      console.log(`Calling API: ${url}`);
    await axios
      .get(url, { headers: {'Ocp-Apim-Subscription-Key':  process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY } })
      .then((res) => {
        res.data.forEach((planSet) => {
          if (planSet.cardType === 'ARCHARGE') {
            response.plans = planSet.plans;
          }
        });
      })
      .catch((err) => {
        response.error = err;
      });

    return response;
  };

  validateCardNumber = (cardNumber: string, expectedLength: number[], luhnCheck: boolean): boolean => {
    if (expectedLength.indexOf(cardNumber.length) === -1) {
      return false;
    }
    if (luhnCheck && !this.luhnCheck(cardNumber)) {
      return false;
    }
    return true;
  };

  validatePaymentPlan = (paymentPlan: string): boolean => {
    return !!paymentPlan;
  };

  validateSecurityCode = (securityCode: string, expectedLength: number[]): boolean => {
    if (!securityCode) {
      return false;
    }
    return expectedLength.indexOf(securityCode.length) > -1;
  };

  validateExpiration = (month: string, year: string): boolean => {
    const current = new Date();
    const currentMonth = current.getMonth() + 1;
    const currentYear = current.getFullYear();
    const expMonth = Number(month);
    const expYear = Number(year);

    if (!expYear || !expMonth) {
      return false;
    }

    if (currentYear > expYear) {
      return false;
    }

    if (currentYear === expYear && currentMonth > expMonth) {
      return false;
    }

    return true;
  };

  preparePayment = (form: Payment.Form): ValidationResults => {
    const { cardNumber, paymentPlan, paymentPlanId, expMonth, expYear, cvv } = form;
    const sanitizedCard = this.sanitizeCard(cardNumber || '');
    const cardInfo = this.getCardInfo(sanitizedCard);

    const results: Payment.ValidationResults = {
      valid: true,
      sanitized: {
        cardNumber: undefined,
        cardType: undefined,
        typeCode: undefined,
        isArcharge: undefined,
        paymentPlan: undefined,
        expMonth: undefined,
        expYear: undefined,
        cvv: undefined,
      },
      errors: {
        cardNumber: undefined,
        paymentPlan: undefined,
        expiration: undefined,
        cvv: undefined,
      },
    };

    if (cardInfo) {
      results.sanitized.cardType = cardInfo.type;
      results.sanitized.typeCode = cardInfo.typeCode;

      const validCardNumber = this.validateCardNumber(sanitizedCard, cardInfo.cardLength, cardInfo.luhn);

      if (!validCardNumber) {
        results.valid = false;
        results.errors.cardNumber = ValidationMessage.invalidCard;
      } else {
        results.sanitized.cardNumber = sanitizedCard;
      }

      if (cardInfo.type === 'archarge') {
        results.sanitized.isArcharge = true;

        if (!this.validatePaymentPlan(paymentPlan)) {
          results.valid = false;
          results.errors.paymentPlan = ValidationMessage.invalidPaymentPlan;
        } else {
          results.sanitized.paymentPlan = paymentPlanId;
        }
      } else {
        results.sanitized.isArcharge = false;

        if (!this.validateExpiration(expMonth, expYear)) {
          results.valid = false;
          results.errors.expiration = ValidationMessage.invalidExpiration;
        } else {
          results.sanitized.expMonth = expMonth;
          results.sanitized.expYear = expYear;
        }
        if (!this.validateSecurityCode(cvv, cardInfo.cvcLength)) {
          results.valid = false;
          results.errors.cvv = ValidationMessage.invalidSecurityCode;
        } else {
          results.sanitized.cvv = cvv;
        }
      }
    } else {
      results.valid = false;
      results.errors.cardNumber = ValidationMessage.invalidCard;
    }

    return results;
  };

  checkThreshold = async(arhausId: string) : Promise<boolean> =>{
    return new Promise((resolve, reject) => {     
      const url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/checkout/check-threshold/${arhausId}`;
      axios
        .get(url, { headers: {'Ocp-Apim-Subscription-Key':  process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY } })
        .then(res => {
          if (res.data?.validationError) {
            return reject(res.data?.message);
          }
          resolve(true);
        })
        .catch(() => {
         reject('api failure');
        });
    })    
  }

  processPayment = async (request: Payment.Request, gtmEnabled: boolean): Promise<string> => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/Checkout/processpayment`;

      const tagManagerData = {
        dataLayer: {
          event: 'add_payment_info',
          data: ''
        }
      }

      axios
        .post(url, request, { headers: {'Ocp-Apim-Subscription-Key':  process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY } })
        .then((res) => {
          try {
            if (gtmEnabled) {
              console.log('calling tag manager');
              tagManagerData.dataLayer.data = JSON.parse(res.data.paymentInfoTag);
              TagManager.dataLayer(tagManagerData)
              console.log('called tag manager');
            } else {
              console.log('google tag manager(GTM) not enabled');
            }
          } catch (error) {
            console.error('error occurred while processing google tag manager', error);
          }
          if (res.data.redirectUrl) {
            //Clear Cookie if available for instant approve
            const cookies = new Cookies();
            const instantApprove = cookies.get("ArchargeInstantApprove");
            if (instantApprove) {
              cookies.remove("ArchargeInstantApprove", { domain: ".arhaus.com", path: '/' });
            }

            resolve(res.data.redirectUrl);
          }

          if (res.data.validationError) {
            reject(res.data.message);
          }
          else if (res.data.technicalError) {
            reject('api failure');
          }
          else {
            // defaulted
            console.error('error occurred during payment processing ', res.data.message)
            reject('Something went wrong, Please retry.');
          }
        })
        .catch(() => {
          reject('api failure');
        });
    });
  };

  getAccessBlock = async (paymentInfo: ValidationResults['sanitized']): Promise<Payment.Shift4TokenRequest> => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/Checkout/accessblock`;

      axios
        .get(url, { headers: {'Ocp-Apim-Subscription-Key':  process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY } })
        .then((res) => {
          resolve({
            url: res.data.url,
            payload: {
              fuseaction: 'api.jsonPostCardEntry',
              i4go_accessblock: res.data.accessBlock,
              i4go_cardtype: paymentInfo.typeCode,
              i4go_cardnumber: paymentInfo.cardNumber,
              i4go_expirationmonth: paymentInfo.expMonth,
              i4go_expirationyear: paymentInfo.expYear,
              i4go_cvv2code: paymentInfo.cvv,
            },
          });
        })
        .catch(() => reject('api failure'));
    });
  };

  getCardToken = async (request: { payload: Payment.Shift4Payload; url: string }): Promise<Payment.Shift4Token> => {
    return new Promise((resolve, reject) => {
      const { payload, url } = request;
      const dataPayload = new URLSearchParams(payload).toString();

      axios({
        method: 'POST',
        url,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: dataPayload,
      })
        .then((response) => {
          if (response.data.i4go_response === 'SUCCESS') {
            resolve({
              token: response.data.i4go_utoken,
              uid: response.data.i4go_uniqueid,
            });
          } else {
            reject('card token failure');
          }
        })
        .catch(() => {
          reject('api failure');
        });
    });
  };

  buildRequest = async (paymentInfo: ValidationResults['sanitized'], arhausId: string): Promise<Payment.Request> => {
    const options = { limit: Number(process.env.REACT_APP_TOKENIZE_RETRIES), delay: 2000 };
    const retrier = new Retrier(options);
    
    return new Promise((resolve, reject) => {      
      if (paymentInfo.isArcharge) {
        resolve({
          arhausId,
          isArcharge: true,
          cardType: paymentInfo.cardType,
          cardNumber: paymentInfo.cardNumber,
          paymentPlan: paymentInfo.paymentPlan,
        });
      } else {
        this.getAccessBlock(paymentInfo)
          .then((accessBlock) => retrier.resolve(() => this.getCardToken(accessBlock)))
          .then((results) => {
            const paymentRequest: Payment.Request = {
              arhausId,
              isArcharge: false,
              cardType: paymentInfo.cardType,
              paymentProcessorToken: results.token,
              paymentProcessorUniqueId: results.uid,
            }
      
            if (paymentInfo.cvv) {
              paymentRequest.securityCode = paymentInfo.cvv
            }

            resolve({
              ...paymentRequest
            });
          })
          .catch((err) => {
            reject('api failure');
          });
      }
    });
  };
}

export default PaymentService;
