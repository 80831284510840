import { FunctionComponent, useEffect, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import Modal from 'react-modal';
import { useLoader } from '../../Context/LoaderContext';

Modal.setAppElement('#root');

const LoaderTransitionStyles = createGlobalStyle`
  .ReactModal {
    &__Overlay {
      opacity: 0;
      transition: opacity 500ms ease-in-out;

      &--after-open {
        opacity: 1;
      }

      &--before-close {
        opacity: 0;
      }
    }
  }
`;

const Loader: FunctionComponent = () => {
  const { loaderState } = useLoader();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen && loaderState.count === 0) {
      setIsOpen(false);
    }

    if (!isOpen && loaderState.count > 0) {
      setIsOpen(true);
    }
  }, [loaderState.count]);

  return (
    <>
      <LoaderTransitionStyles />

      <Modal
        isOpen={isOpen}
        contentLabel="Loading"
        aria={{ describedby: '#loadingDescription' }}
        closeTimeoutMS={500}
        style={{
          /* stylelint-disable selector-type-no-unknown */
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.7)',
          },
          content: {
            backgroundColor: 'transparent',
            color: '#fff',
            fontWeight: 'bold',
            border: 0,
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignItems: 'center',
          },
          /* stylelint-enable selector-type-no-unknown */
        }}
      >
        <span>Loading, please wait.</span>
      </Modal>
    </>
  );
};

export default Loader;
