import React from "react";
import styled from 'styled-components';
import Label from '../Label';

interface Props {
  id: string;
  isChecked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

const Checkbox = (props: Props) => {
  return (
    <div>
      <input type="checkbox"
        id={props.id}
        checked={props.isChecked}
        onChange={props.handleChange}
      /> &nbsp;
      <Label htmlFor={props.label}>{props.label} </Label>
    </div>
  );
};

Checkbox.StyledInput = styled.input`
  margin-right : 10px;
  margin-top   : 3px;
  width        : 150px;
  height       : 25px;
  cursor       : pointer;

  &:checked:after{
    background-color:green;
  }
`;

Checkbox.Wrapper = styled.div`
  display    : flex;
  text-align : justify;
`;

export default Checkbox;