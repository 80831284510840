import styled from 'styled-components';

const PageContainer = styled.div(({ theme }) => {
  const { size } = theme;

  return `
    max-width: 527px;
    min-width: ${size.baseMinWidth};
    margin: 0 auto;
    padding: 0 15px;

    @media screen and (min-width: 557px) {
      padding: 0;
    }
  `;
});

export default PageContainer;
