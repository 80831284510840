import axios from 'axios';
import { config } from 'process';


export interface ICapthcaService {
    validateToken(token:string): Promise<boolean>;
}

class CaptchaService implements ICapthcaService {
  
  validateToken = async (token:string) : Promise<boolean> =>
  {
    return new Promise((resolve, reject) => {
        let error: Error;
        const url = `${process.env.REACT_APP_API_URL}v2/captcha/verifytoken`
         axios
            .post(url, { "token": token }, { headers: {'Ocp-Apim-Subscription-Key':  process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY } })
            .then((res) => {
                if (res.data.technicalError) {
                    reject('api failure');
                }
                resolve(res.data.success)
            })
            .catch(() => {
                reject('api failure');
            });
    });
    }
}

export default CaptchaService;