import { FC } from 'react';
import sanitizeHtml from 'sanitize-html';

export interface IHTMLBlock {
  html: string;
}

const HTMLBlock: FC<IHTMLBlock> = ({ html }) => {
  const sanitized = sanitizeHtml(html, {
    allowedTags: ['a', 'strong', 'em', 'br'],
  });

  return <div dangerouslySetInnerHTML={{ __html: sanitized }} />;
};

export default HTMLBlock;
