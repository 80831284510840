import { FC } from 'react';
import styled, { DefaultTheme } from 'styled-components';

export interface IValidationMessage {
  message?: string;
  id: string;
  theme?: DefaultTheme;
}

const ErrorMessage = styled.div(({ theme }) => {
  const { color, type } = theme;

  return `
    color: ${color.utility.error};
    font-style: italic;
    font-size: calc(#{${type.baseSize}} - 4px);
  `;
});

const ValidationMessage: FC<IValidationMessage> = ({ message, theme, id }) => {
  return (
    <>
      {message && (
        <ErrorMessage id={id} theme={theme}>
          {message}
        </ErrorMessage>
      )}
    </>
  );
};

export default ValidationMessage;
