import { Site } from '../Types';

const shopifyUrl = '';

export const logoLink: Site.Link = {
  url: shopifyUrl,
  linkText: 'Return to Arhaus.com',
};

export const breadcrumbLinks: Site.Breadcrumb[] = [
  {
    url: `${shopifyUrl}cart/`,
    linkText: 'Cart',
    useId: false,
  },
  {
    url: `${shopifyUrl}checkouts/`,
    linkText: 'Information',
    query: '?step=contact_information',
    useId: true,
  },
  {
    url: `${shopifyUrl}checkouts/`,
    query: '?step=shipping_method',
    linkText: 'Shipping',
    useId: true,
  },
  {
    url: `${shopifyUrl}checkouts/`,
    query: '?step=payment_method',
    linkText: 'Billing',
    useId: true,
  },
];

export const footerLinks: Site.Link[] = [
  {
    url: '#',
    linkText: 'Refund Policy',
  },
  {
    url: '#',
    linkText: 'Shipping Policy',
  },
  {
    url: '#',
    linkText: 'Privacy Policy',
  },
  {
    url: '#',
    linkText: 'Terms of Service',
  },
];
