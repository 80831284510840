import styled from 'styled-components';

const BlockHeader = styled.h2(({ theme }) => {
  const { breakpoint, type } = theme;

  return `
    font-size: 0.75rem;
    font-weight: ${type.weight.regular};
    font-family: NeueHaasUnica;
    text-transform: capitalize;
    margin: 37px 0 10px 0;

    @media screen and (min-width: ${breakpoint.phone}) {
      font-size: 1rem;
      margin: 45px 0 19px 0;
    }
  `;
});

export default BlockHeader;
