import axios from 'axios';
import { config } from 'process';
import { Payment, Shopify } from '../Types';

export interface IShopifyCustomerService {
  validateUniqueIdAsync(id: string, archargeString: string): Promise<Shopify.ValidateUniqueIdResponse>;
}

class ShopifyCustomerService implements IShopifyCustomerService {
  validateUniqueIdAsync = async (id: string, archargeString: string): Promise<Shopify.ValidateUniqueIdResponse> => {
    let customerInfo: Payment.Customer;
    let error: Error;

    const url = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/checkout/cart/${id}`;
    await axios
      .get<Payment.Customer>(url, { headers: { encryptedDetails: archargeString? archargeString:"" ,
         'Ocp-Apim-Subscription-Key':  process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY } })
      .then((res) => {
        customerInfo = res.data;
      })
      .catch((err) => {
        error = err;
      });

    return { customerInfo, error };
  };
}

export default ShopifyCustomerService;
